import React, { useEffect, useState } from 'react'
import './SelectIndustry.css'
import { Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setValuationOnboardDataIntoRedux } from '../../../redux/actions/valuationAction'
import { setAddIndustryMixModal } from '../../../redux/actions/companyAction'
import { checkMixIndustriesUpdateValid } from '../../CompanyProfile/IndustryMix/IndustryMix'
import { ET_INDUSTRY_CONTINUE_BTN_CLICK } from '../../../common/trackingConstants'
import { getBusinessDescriptionOpenAi } from '../../../redux/actions/businessPlanAction'
import { setLoading } from '../../../redux/actions/commonAction'

const tabMenuData = [{
    title: "AI Industry Finder",
    id: "ai_finder",
    iconObj: {
        src: "assets/svgs/pom_ai_chip_spark_solid_icon.svg",
        width: "25px",
        height: "25px",
        alt: "ai chip spark solid icon"
    }
},
{
    title: "Manual Industry look up",
    id: "manual_finder",
    iconObj: {
        src: "assets/svgs/pom_ai_note_pad_icon.svg",
        width: "25px",
        height: "25px",
        alt: "note pad icon"
    }
}
]

const SelectIndustry = (props) => {
    const reduxValuationObj = useSelector(state => state.valuation?.valutionOnboardObj)
    const { industryList = [], componentActive, industryFinder = "ai_finder", indMixValidtion = false, aiIndustryFinderText="" } = reduxValuationObj   
    const dispatch = useDispatch()
    const onContinueClick = () => {
        if(industryFinder === "ai_finder" && aiIndustryFinderText && !industryList.length) {
            dispatch(setLoading({status: true, timeout: null}));
            dispatch(getBusinessDescriptionOpenAi(aiIndustryFinderText, "valuation"))
        }
        if(industryList.length) {
            const checkIndustryIsValid = checkMixIndustriesUpdateValid(industryList);
            dispatch(setValuationOnboardDataIntoRedux({ indMixValidtion: !checkIndustryIsValid }))
            if (checkIndustryIsValid) {
                props.handleValuationOnboardTracking({ eventType: ET_INDUSTRY_CONTINUE_BTN_CLICK })
                dispatch(setValuationOnboardDataIntoRedux({
                    componentId: "buy_business_valuation"
                }))
            }
        }
    }
    const onInputClick = () => {
        dispatch(setAddIndustryMixModal(true));
        dispatch(setValuationOnboardDataIntoRedux({
            industryEditFlag: true,
        }))
    }
    const onChange = (e) => {
        dispatch(setValuationOnboardDataIntoRedux({
            aiIndustryFinderText: e.target.value,
            componentActive: e.target.value !== ""
        }));
    }
    const onInputBoxHandler = (userValue, industryObj) => {
        const newUpdatedMixIndustries = industryList.map(obj => obj?.SIC2007 === industryObj?.SIC2007 ?
            { ...obj, value: userValue } : obj)
        dispatch(setValuationOnboardDataIntoRedux({
            industryList: newUpdatedMixIndustries
        }));
    }
    const onCancelBtnClick = (cancelObj) => {
        const removedIndustryMix = industryList.filter(item => item.SIC2007 !== cancelObj?.SIC2007)
        dispatch(setValuationOnboardDataIntoRedux({ industryList: removedIndustryMix }));
    }
    useEffect(() => {
        const activeStatus = industryList?.length ? true : false
        dispatch(setValuationOnboardDataIntoRedux({
            componentActive: activeStatus
        }))
    }, [industryList?.length])
    const onTabChange = (id) => {
        dispatch(setValuationOnboardDataIntoRedux({
            industryFinder: id
        }));
    }
    return (
        <div id='select_industry'>
            <div className='val-ind-comp-tab-section-wrapper'>
                {tabMenuData.map(obj => <div className={`val-ind-comp-tab-section ${industryFinder == obj.id ? "active-tab" : ""}`}
                    key={obj.title} onClick={() => onTabChange(obj.id)}>
                    <picture>
                        <source media='(max-width: 575.98px)' width="20" height="20" />
                        <img {...obj.iconObj} />
                    </picture>
                    <p>{obj.title}</p>
                </div>)}
            </div>
            {industryFinder === "ai_finder" ? <textarea className='val-ind-comp-tab-section-desc'
                name="aiIndustryFinderText" value={aiIndustryFinderText} onChange={onChange} placeholder='Provide a brief overview of what the business does, focus on key products or services, and let AI determine the industry(s)...'></textarea>
                : <div className='val-ind-comp-ind-search-div'><img src='assets/svgs/search_icon.svg' />
                    <Input
                        className='border-0 p-0'
                        placeholder="Search for an industry"
                        onClick={() => onInputClick()}
                    /></div>}
            {industryList?.length ? <><p className='val-ind-comp-label'>Industry Breakdown</p>
                <div className='val-ind-comp-selected-ind-list'>
                    {industryList.map(obj => <div className='val-ind-comp-selected-ind'>
                        <p className='selected-ind-heading'>{obj.name}</p>
                        <Input
                            value={obj.value}
                            onChange={(e) => onInputBoxHandler(e.target.value, obj)}
                            className='selected-ind-input-field' />
                        <i className='fas fa-close yellow-color-text cursor-pointer' onClick={() => onCancelBtnClick(obj)} />
                    </div>)}
                    {industryList?.length < 5 && <p className='add-ind-more-btn' onClick={() => onInputClick()}>Add More Industries +</p>}
                </div></> : null}
            {indMixValidtion && <p className='invalid-input-message'>Check that the industry breakdown adds up to 100%</p>}
            <div className='valuation-right-side-btn-wrapper'>
                <button className={`valuation-right-side-component-btn ${componentActive ? "footer-active-btn" : ""}`}
                    disabled={!componentActive} onClick={() => onContinueClick()}>{industryList?.length ? "CONTINUE" : "FIND INDUSTRY"}</button>
            </div>

        </div >
    )
}

export default SelectIndustry
import { call, put, takeLatest, select, all, fork } from "redux-saga/effects";
import {
    companySearchFromNameApiCall,
    getDirectorListRequestApiCall,
    getIndustryFilterDataApiCall,
    singleSearchApiCall,
    saveCurrentSearchApi,
    getSaveSearchesApi,
    deleteSavedSearchApi,
    downloadResultsApiCall,
    sendIncreaseDownloadRequestApiCall,
    getIndustryListRequestApiCall,
    getShareholderListRequestApiCall,
    getChargesListRequestApiCall,
    getRegionListRequestApiCall
} from '../api/searchApi';
import {
    SEARCH_COMPANY_API,
    GET_COMPANY_SEARCH_RESULTS,
    GET_DIRECTOR_LIST_REQUEST,
    // SEARCH_COMPANY_API_SUCCESS,
    // SEARCH_COMPANY_API_FAILED,
    // SET_COMPANY_SEARCH_APPLIED_FILTER,
    GET_INDUSTRY_FILTER_DATA_REQUEST,
    CALL_SINGLE_SEARCH_API,
    GET_SAVE_SEARCHES,
    SAVE_CURRENT_SEARCH,
    DELETE_SAVED_SEARCH,
    DOWNLOAD_RESULTS_REQUEST,
    INCREASE_DOWNLOAD_RESULT_LIMIT_REQUEST,
    GET_INDUSTRY_LIST_REQUEST,
    GET_SHAREHOLDER_LIST_REQUEST,
    GET_CHARGES_LIST_REQUEST,
    RESET_ALL_SEARCH_PARAMS,
    GET_REGION_LIST_REQUEST
} from '../redux/actions/actionConstants';
import {
    searchCompanyApiSuccess,
    searchCompanyApiFailed,
    getDirectorListSuccess,
    getDirectorListFailed,
    setIndustryFilterData,
    getIndustryFilterDataFailed,
    callSingleSearchApiErrorAction,
    setSavedSearchesList,
    setSaveCurrentSearchError,
    setAddCurrentSearch,
    setSavedSearches,
    setResetFilterType,
    setCompanySearchParams,
    setDirectorSearchParams,
    getIndustryListSuccess,
    getShareholderListSuccess,
    setShareholderSearchParams,
    setChargesSearchParams,
    setIndustrySearchParams,
    getChargesListSuccess,
    getRegionListSuccess,
    setRegionSearchParams,
    setDownloadSearchResultsBtnStatus
} from '../redux/actions/searchAction';
import { sendActivityTracking } from "../redux/actions/commonAction";
import { powerSearchCompanyActivityTracking, homepageSingleSearchActivityTracking } from './activityTrackingSaga'
import apiFilterParser from '../common/searchRequestParser';
import download from "downloadjs"
import { updateUserInfo } from "../redux/actions/userAccountAction";
import { setConfirmationModal, toggleCommonMsgModal, setHeaderSearchText } from "../redux/actions/commonAction";
import successModalConstants from '../common/messageModalConstants';
import {
    ET_RESET_FILTER_SUCCESS, ET_RESET_FILTER_FAILED,
    EP_POWER_SEARCH_COMPANY, EP_POWER_SEARCH_DIRECTOR, EP_POWER_SEARCH_INDUSTRY, EP_POWER_SEARCH_SHAREHOLDER, EP_POWER_SEARCH_CHARGES, EP_POWER_SEARCH_REGION
} from "../common/trackingConstants";
import { setIndustryMixLoader } from '../redux/actions/companyAction';
import { MAX_CSV_DOWNLOAD_LIMIT } from "../common/config";
const isEstimateOn = (state) => state.searchReducer.isEstimateOn;
const oldCompanyCount = (state) => state.searchReducer.searchCompanyCount;
const oldDirectorCount = (state) => state.searchReducer.searchDirectorCount;
const userInfo = (state) => state.userProfile.userInfo
const common = (state) => state.common
const resetFilterType = (state) => state.searchReducer.resetFilterType;
const CSV_DEFAULT_HEADER = "Download in progress, please check your download folder."

function* callSearchApiSaga(_action) {
    try {
        const { trackingObj, ...payload } = _action.payload;
        let companyQueryData = { ...payload, isEstimateOn: yield select(isEstimateOn) };
        companyQueryData.filters = apiFilterParser(payload.filters);
        let response = yield call(companySearchFromNameApiCall, companyQueryData);
        const resetType = yield select(resetFilterType);
        if (!response.error) {
            if (trackingObj && Object.keys(trackingObj).length > 0) {
                yield call(powerSearchCompanyActivityTracking, { ...trackingObj, oldResultCount: yield select(oldCompanyCount), newResultCount: response.data.total });
            }
            yield put(searchCompanyApiSuccess(response.data));
            if (resetType === "companyFilterReset") {
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_COMPANY,
                        eventType: ET_RESET_FILTER_SUCCESS
                    })),
                    put(setResetFilterType(""))
                ])
            }
        } else {
            yield put(searchCompanyApiFailed());
            if (resetType === "companyFilterReset") {
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_COMPANY,
                        eventType: ET_RESET_FILTER_FAILED
                    })),
                    put(setResetFilterType(""))
                ])
            }
        }
    } catch (error) {
        yield put(searchCompanyApiFailed());
    }
}

export function* watchCallSearchApiSaga() {
    yield takeLatest(SEARCH_COMPANY_API, callSearchApiSaga);
}

export function* watchGetCompanySearchResultsSaga() {
    yield takeLatest(GET_COMPANY_SEARCH_RESULTS, callSearchApiSaga);
}

// export function* watchgetCompanyListWithFiltersSaga() {
//     yield takeLatest(SET_COMPANY_SEARCH_APPLIED_FILTER, callSearchApiSaga);
// }

function* getDirectorListRequestSaga(action) {
    try {
        const { trackingObj, ...payload } = action.payload.directorQueryData;
        let directorQueryData = { ...payload, isEstimateOn: yield select(isEstimateOn) };
        directorQueryData.filters = apiFilterParser(payload.filters);
        let response = yield call(getDirectorListRequestApiCall, directorQueryData);
        const resetType = yield select(resetFilterType);
        if (!response.error) {
            if (trackingObj && Object.keys(trackingObj).length > 0) {
                yield call(powerSearchCompanyActivityTracking, { ...trackingObj, oldResultCount: yield select(oldDirectorCount), newResultCount: response.data.total });
            }
            yield put(getDirectorListSuccess(response.data));
            if (Object.keys(action.payload.directorQueryData.filters).length !== 0 && action.payload.directorQueryData.filters.constructor === Object) {
                // yield put(setDirectorFilterAppliedIndicator(true));
            }
            if(resetType==="directorFilterReset"){
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_DIRECTOR,
                        eventType: ET_RESET_FILTER_SUCCESS
                    })),
                    put(setResetFilterType(""))
                ])
            }
        } else {
            // yield put(setDirectorFilterAppliedIndicator(false));
            yield put(getDirectorListFailed());
            if (resetType === "directorFilterReset") {
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_DIRECTOR,
                        eventType: ET_RESET_FILTER_FAILED
                    })),
                    put(setResetFilterType(""))
                ])
            }
        }
    } catch (error) {
        // yield put(setDirectorFilterAppliedIndicator(false));
        yield put(getDirectorListFailed());
    }
}
export function* watchgetDirectorListRequestSaga() {
    yield takeLatest(GET_DIRECTOR_LIST_REQUEST, getDirectorListRequestSaga);
}

function* getIndustryListRequestSaga(action) {
    try {
        const { trackingObj, ...payload } = action.payload.industryQueryData;
        let industryQueryData = { ...payload, isEstimateOn: yield select(isEstimateOn) };
        industryQueryData.filters = apiFilterParser(payload.filters);
        let response = yield call(getIndustryListRequestApiCall, industryQueryData);
        const resetType = yield select(resetFilterType);
        if (!response.error) {
            if (trackingObj && Object.keys(trackingObj).length > 0) {
                yield call(powerSearchCompanyActivityTracking, { ...trackingObj, oldResultCount: yield select(oldDirectorCount), newResultCount: response.data.total });
            }
            yield put(getIndustryListSuccess(response.data));
            if(resetType==="industryFilterReset"){
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_INDUSTRY,
                        eventType: ET_RESET_FILTER_SUCCESS
                    })),
                    put(setResetFilterType(""))
                ])
            }
        } else {
            // yield put(getDirectorListFailed());
            if (resetType === "industryFilterReset") {
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_INDUSTRY,
                        eventType: ET_RESET_FILTER_FAILED
                    })),
                    put(setResetFilterType(""))
                ])
            }
        }
    } catch (error) {
        // yield put(getDirectorListFailed());
        console.log("error in getIndustryListRequestSaga",error)
    }
}

export function* watchgetIndustryListRequestSaga() {
    yield takeLatest(GET_INDUSTRY_LIST_REQUEST, getIndustryListRequestSaga);
}

function* getRegionListRequestSaga(action) {
    try {
        const { trackingObj, ...payload } = action.payload.regionQueryData;
        let regionQueryData = { ...payload, isEstimateOn: yield select(isEstimateOn) };
        regionQueryData.filters = apiFilterParser(payload.filters);
        let response = yield call(getRegionListRequestApiCall, regionQueryData);
        const resetType = yield select(resetFilterType);
        if (!response.error) {
            if (trackingObj && Object.keys(trackingObj).length > 0) {
                yield call(powerSearchCompanyActivityTracking, { ...trackingObj, oldResultCount: yield select(oldDirectorCount), newResultCount: response.data.total });
            }
            yield put(getRegionListSuccess(response.data));
            if(resetType==="regionFilterReset"){
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_REGION,
                        eventType: ET_RESET_FILTER_SUCCESS
                    })),
                    put(setResetFilterType(""))
                ])
            }
        } else {
            if (resetType === "regionFilterReset") {
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_REGION,
                        eventType: ET_RESET_FILTER_FAILED
                    })),
                    put(setResetFilterType(""))
                ])
            }
        }
    } catch (error) {
        console.log("error in getRegionListRequestSaga",error)
    }
}

export function* watchgetRegionListRequestSaga() {
    yield takeLatest(GET_REGION_LIST_REQUEST, getRegionListRequestSaga);
}

function* getShareholderListRequestSaga(action) {
    try {
        const { trackingObj, ...payload } = action.payload.shareholderQueryData;
        let shareholderQueryData = { ...payload, isEstimateOn: yield select(isEstimateOn) };
        shareholderQueryData.filters = apiFilterParser(payload.filters);
        let response = yield call(getShareholderListRequestApiCall, shareholderQueryData);
        // let response = {
        //     data: { searchData, total }
        // }
        const resetType = yield select(resetFilterType);
        if (!response.error) {
            if (trackingObj && Object.keys(trackingObj).length > 0) {
                yield call(powerSearchCompanyActivityTracking, { ...trackingObj, oldResultCount: yield select(oldDirectorCount), newResultCount: response.data.total });
            }
            yield put(getShareholderListSuccess(response.data));
            if(resetType==="shareholderFilterReset"){
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_SHAREHOLDER,
                        eventType: ET_RESET_FILTER_SUCCESS
                    })),
                    put(setResetFilterType(""))
                ])
            }
        } else {
            // yield put(getDirectorListFailed());
            if (resetType === "shareholderFilterReset") {
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_SHAREHOLDER,
                        eventType: ET_RESET_FILTER_FAILED
                    })),
                    put(setResetFilterType(""))
                ])
            }
        }
    } catch (error) {
        // yield put(getDirectorListFailed());
        console.log("error in getShareholderListRequestSaga",error)
    }
}

export function* watchgetShareholderListRequestSaga() {
    yield takeLatest(GET_SHAREHOLDER_LIST_REQUEST, getShareholderListRequestSaga);
}

function* getChargesListRequestSaga(action) {
    try {
        const { trackingObj, ...payload } = action.payload.chargesQueryData;
        let chargesQueryData = { ...payload, isEstimateOn: yield select(isEstimateOn) };
        chargesQueryData.filters = apiFilterParser(payload.filters);
        let response = yield call(getChargesListRequestApiCall, chargesQueryData);
        // let response = {
        //     data: { searchData, total }
        // }
        const resetType = yield select(resetFilterType);
        if (!response.error) {
            if (trackingObj && Object.keys(trackingObj).length > 0) {
                yield call(powerSearchCompanyActivityTracking, { ...trackingObj, oldResultCount: yield select(oldDirectorCount), newResultCount: response.data.total });
            }
            yield put(getChargesListSuccess(response.data));
            if(resetType==="chargesFilterReset"){
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_CHARGES,
                        eventType: ET_RESET_FILTER_SUCCESS
                    })),
                    put(setResetFilterType(""))
                ])
            }
        } else {
            // yield put(getDirectorListFailed());
            if (resetType === "chargesFilterReset") {
                yield all([
                    put(sendActivityTracking({
                        eventPage: EP_POWER_SEARCH_CHARGES,
                        eventType: ET_RESET_FILTER_FAILED
                    })),
                    put(setResetFilterType(""))
                ])
            }
        }
    } catch (error) {
        // yield put(getDirectorListFailed());
        console.log("error in getChargesListRequestSaga",error)
    }
}

export function* watchgetChargesListRequestSaga() {
    yield takeLatest(GET_CHARGES_LIST_REQUEST, getChargesListRequestSaga);
}

function* getIndustryFilterDataSaga() {
    try {
        let response = yield call(getIndustryFilterDataApiCall);
        if (!response.error) {
            yield put(setIndustryFilterData({ 
                industryFilterData: response.industryFilterData, 
                locationFilterData: response.locationFilterData, 
                postCodeDistrictList: response.postCodeDistrictList,
                industryListForMix: response.industryListForIndMixEdit
            }));
        } else {
            yield put(getIndustryFilterDataFailed(response.error));
        }
        yield put(setIndustryMixLoader({ show: false, message: "" }));
    } catch (error) {
        yield put(getIndustryFilterDataFailed(error));
    }
}

export function* watchgetIndustryFilterDataSaga() {
    yield takeLatest(GET_INDUSTRY_FILTER_DATA_REQUEST, getIndustryFilterDataSaga);
}

function* requestAndPut(requestParameters, actionCreatorObj) {
    const response = yield call(...requestParameters);
    if (!response.error) {
        yield all([
            put(actionCreatorObj.success(response)),
            put(actionCreatorObj.setParam()),
            call(actionCreatorObj.setSingleSearchData, response)
        ])
    } else {
        console.log("error in requestAndPut",response.error)
    }
}

const getSearchQueryObj = (queryData, searchType) => {
    return {
        from: queryData.from, 
        size: queryData.size, 
        searchText: queryData.searchText, 
        sortField: queryData[searchType].sortField, 
        sortType: queryData[searchType].sortType
    }
}

function* callSingleSearchApiSaga(_action) {
    let {trackingObj, ...queryData} = _action.payload.searchObj;
    try {
        // if (queryData.isFilters) {
        //     queryData.companyFilters = apiFilterParser(queryData.companyFilters);
        //     queryData.directorFilters = apiFilterParser(queryData.directorFilters);
        // }
        let singleSearchData = {};
        const singleSearchForkList = [
            call(requestAndPut, [singleSearchApiCall, {...queryData, searchType: "company"}], {
                success: (response)=>searchCompanyApiSuccess(response.data.searchResponse),
                setParam: ()=>setCompanySearchParams(getSearchQueryObj(queryData, "company")),
                setSingleSearchData: (response) => {singleSearchData = {...singleSearchData, companyCount: response.data.searchResponse.total}}
            }),
            call(requestAndPut, [singleSearchApiCall, {...queryData, searchType: "director"}], {
                success: (response)=>getDirectorListSuccess(response.data.searchResponse),
                setParam: ()=>setDirectorSearchParams(getSearchQueryObj(queryData, "director")),
                setSingleSearchData: (response) => {singleSearchData = {...singleSearchData, directorCount: response.data.searchResponse.total}}
            }),
            call(requestAndPut, [singleSearchApiCall, {...queryData, searchType: "industry"}], {
                success: (response)=>getIndustryListSuccess(response.data.searchResponse),
                setParam: ()=>setIndustrySearchParams(getSearchQueryObj(queryData, "industry")),
                setSingleSearchData: (response) => {singleSearchData = {...singleSearchData, industryCount: response.data.searchResponse.total}}
            }),
            call(requestAndPut, [singleSearchApiCall, {...queryData, searchType: "shareholder"}], {
                success: (response)=>getShareholderListSuccess(response.data.searchResponse),
                setParam: ()=>setShareholderSearchParams(getSearchQueryObj(queryData, "shareholder")),
                setSingleSearchData: (response) => {singleSearchData = {...singleSearchData, shareholderCount: response.data.searchResponse.total}}
            }),
            call(requestAndPut, [singleSearchApiCall, {...queryData, searchType: "charges"}], {
                success: (response)=>getChargesListSuccess(response.data.searchResponse),
                setParam: ()=>setChargesSearchParams(getSearchQueryObj(queryData, "charges")),
                setSingleSearchData: (response) => {singleSearchData = {...singleSearchData, chargesCount: response.data.searchResponse.total}}
            }),
            call(requestAndPut, [singleSearchApiCall, {...queryData, searchType: "region"}], {
                success: (response)=>getRegionListSuccess(response.data.searchResponse),
                setParam: ()=>setRegionSearchParams(getSearchQueryObj(queryData, "region")),
                setSingleSearchData: (response) => {singleSearchData = {...singleSearchData, regionCount: response.data.searchResponse.total}}
            }),
        ];
        const selectedForkList = singleSearchForkList.filter((forkObj)=> {
            const searchTypes = forkObj.payload.args[0][1].searchTypes;
            const searchType = forkObj.payload.args[0][1].searchType; 
            return searchTypes.includes(searchType);
        });
        yield all(selectedForkList);
        // activity tracking needs to be done........
        if(Object.keys(trackingObj)?.length > 0) {
            trackingObj = { 
                ...trackingObj, 
                ...singleSearchData
            }
            yield call(homepageSingleSearchActivityTracking, trackingObj)
        }
        // let response = yield call(singleSearchApiCall, queryData);
        // if (!response.error) {
        //     yield all([
        //         put(searchCompanyApiSuccess(response.data.company)),
        //         put(getDirectorListSuccess(response.data.director)),
        //         put(setCompanySearchParams(queryData)),
        //         put(setDirectorSearchParams(queryData))
        //     ])
        //     // if (trackingObj && Object.keys(trackingObj).length > 0) {
        //     //     yield call(homepageSingleSearchActivityTracking, { ...trackingObj, companyCount: response.data.company.total, directorCount: response.data.director.total });
        //     // }
        // } else {
        //     yield put(callSingleSearchApiErrorAction());
        // }

    } catch (error) {
        yield put(callSingleSearchApiErrorAction());
    }
}

export function* watchCallSingleSearchApiSaga() {
    yield takeLatest(CALL_SINGLE_SEARCH_API, callSingleSearchApiSaga);
}

function* callGetSaveSearchesSaga(action) {
    try {
        const user = yield select(userInfo)
        const {
            _id = ""
        } = user;
        let response = yield call(getSaveSearchesApi, {...action.payload.searchObj, userId:_id});
        if (!response.error) {
            yield put(setSavedSearchesList({list:response.saveSearchesList, count: response.totalCount}));
        } else {
            // yield put(callSingleSearchApiErrorAction());
        }
    } catch (error) {
        console.log("error inside callGetSaveSearchesSaga",error);
        // yield put(callSingleSearchApiErrorAction());
    }
}

export function* watchCallGetSaveSearchesSaga() {
    yield takeLatest(GET_SAVE_SEARCHES, callGetSaveSearchesSaga);
}

function* callSaveCurrentSearchSaga(action) {
    try {
        const user = yield select(userInfo)
        const {
            _id = ""
        } = user;
        let response = yield call(saveCurrentSearchApi, {...action.payload.searchObj, userId:_id});
        if (!response.error) {
            yield all([
                put(setAddCurrentSearch(false)),
                put(setSavedSearches(true)),
                put(setSavedSearchesList({list:response.saveSearchesData, count: response.totalCount}))
            ]);
        } else {
            yield put(setSaveCurrentSearchError(response.message));
        }
    } catch (error) {
        // yield put(callSingleSearchApiErrorAction());
    }
}

export function* watchCallSaveCurrentSearchSaga() {
    yield takeLatest(SAVE_CURRENT_SEARCH, callSaveCurrentSearchSaga);
}

function* callDeleteSavedSearchSaga(action) {
    try {
        const user = yield select(userInfo)
        const {
            _id = ""
        } = user;
        let response = yield call(deleteSavedSearchApi, {_id: action.payload.searchId, userId:_id});
        if (!response.error) {
            yield put(setSavedSearchesList({list:response.saveSearchesList, count: response.totalCount}));
        } else {
            // yield put(callSingleSearchApiErrorAction());
        }
    } catch (error) {
        // yield put(callSingleSearchApiErrorAction());
    }
}

export function* watchCallDeleteSavedSearchSaga() {
    yield takeLatest(DELETE_SAVED_SEARCH, callDeleteSavedSearchSaga);
}

function* downloadResultsSaga(action){
    try {
        const reduxCommonInfo = yield select(common)
        const user = yield select(userInfo)
        let monthlyCSVDownloadCount = reduxCommonInfo?.monthlyCsvRowDownloadCount;
        const {
            subscription = {},
            extraCSVCreditsSubscription = {}
        } = user;
        let {
            usedCsvRowDownloadCount = 0
        } = subscription;
        let remainingDownloadCount = usedCsvRowDownloadCount <= monthlyCSVDownloadCount ? monthlyCSVDownloadCount - usedCsvRowDownloadCount : 0;
        if (extraCSVCreditsSubscription?.status === "active") {
            remainingDownloadCount = remainingDownloadCount + (extraCSVCreditsSubscription.monthlyCsvRowDownloadCount - extraCSVCreditsSubscription.usedCsvRowDownloadCount);
        }
        const queryData = {...action.payload.searchQueryData,isEstimateOn: yield select(isEstimateOn)};
        const powerSearchCount = queryData.size
        
        yield all([
            put(toggleCommonMsgModal({
                toggleStatus: true,
                heading: successModalConstants.PS_CSV_DOWNLOAD_STARTED.header ,
                subHeading: remainingDownloadCount > 1000 && powerSearchCount > 1000 ?
                    successModalConstants.PS_CSV_DOWNLOAD_STARTED.subHeader : CSV_DEFAULT_HEADER,
                btnText: successModalConstants.PS_CSV_DOWNLOAD_STARTED.btnText,
                modalClassName: successModalConstants.PS_CSV_DOWNLOAD_STARTED.modalClassName
            })),
            put(setConfirmationModal(false)),
            put(setDownloadSearchResultsBtnStatus(true))
        ])
        delete queryData.trackingObj;
        queryData.filters = queryData.filters && queryData.filters !== null ? apiFilterParser(queryData.filters) : {};
        remainingDownloadCount = remainingDownloadCount > MAX_CSV_DOWNLOAD_LIMIT ? MAX_CSV_DOWNLOAD_LIMIT : remainingDownloadCount;
        let response = {}
                for (let i = 0; i < remainingDownloadCount; i += 500) {
                    if (i>= powerSearchCount) {
                        break;
                    }
                    queryData.from = i
                    queryData.size = 500
                    const multipleApiResp = yield call(downloadResultsApiCall, queryData);
                    const { data = '' } = response
                    response = {
                        ...multipleApiResp,
                        data: `${data}${multipleApiResp.data}`
                    }
                }
        if(!response.error){
            if (response.data !== null) {
                yield call(download, response.data, "PomandaData.csv", "text/csv");
                yield put(toggleCommonMsgModal({
                    toggleStatus: false,
                    heading: "",
                    subHeading: "",
                    btnText: ""
                }));
            }
            if(response.userInfo){
                yield put(updateUserInfo(response.userInfo));
            }
        } else {
            yield put(toggleCommonMsgModal({
                toggleStatus: true,
                heading: successModalConstants.PS_CSV_DOWNLOAD_FAILED.header,
                subHeading: successModalConstants.PS_CSV_DOWNLOAD_FAILED.subHeader,
                btnText: successModalConstants.PS_CSV_DOWNLOAD_FAILED.btnText,
                modalClassName: successModalConstants.PS_CSV_DOWNLOAD_FAILED.modalClassName
            }));
        }
        yield put(setDownloadSearchResultsBtnStatus(false));
    } catch (error) {
        yield put(setDownloadSearchResultsBtnStatus(false));
        console.error('inside downloadResultsSaga ',error);
    }
}
export function* watchDownloadResults(){
    yield takeLatest(DOWNLOAD_RESULTS_REQUEST,downloadResultsSaga);
}

function* increaseDownloadResultRequestSaga(action){
    try {
        const response = yield call(sendIncreaseDownloadRequestApiCall,action.payload.requestDetails);
        if(!response.error){
            yield all([
                put(setConfirmationModal(false)),
                put(toggleCommonMsgModal({
                    toggleStatus: true,
                    heading: successModalConstants.PS_INCREASE_DOWNLOAD_RESULT_LIMIT_SUCCESS.header,
                    subHeading: successModalConstants.PS_INCREASE_DOWNLOAD_RESULT_LIMIT_SUCCESS.subHeader,
                    btnText: successModalConstants.PS_INCREASE_DOWNLOAD_RESULT_LIMIT_SUCCESS.btnText,
                    modalClassName: successModalConstants.PS_INCREASE_DOWNLOAD_RESULT_LIMIT_SUCCESS.modalClassName
                }))
            ]);
        } else {
            yield all([
                put(setConfirmationModal(false)),
                put(toggleCommonMsgModal({
                    toggleStatus: true,
                    heading: "Error",
                    subHeading: response.message,
                    btnText: "ok"
                }))
            ]);
        }
    } catch (error) {
        console.error('inside increaseDownloadResultRequestSaga ',error);
        yield all([
            put(setConfirmationModal(false)),
            put(toggleCommonMsgModal({
                toggleStatus: true,
                heading: "Error",
                subHeading: error.message,
                btnText: "ok"
            }))
        ]);
    }
}
export function* watchIncreaseDownloadResultLimitRequest(){
    yield takeLatest(INCREASE_DOWNLOAD_RESULT_LIMIT_REQUEST,increaseDownloadResultRequestSaga);
}

function* resetAllSearchParamsSaga(action){
    yield all([
        put(setCompanySearchParams(action.payload.searchParam)),
        put(setDirectorSearchParams(action.payload.searchParam)),
        put(setIndustrySearchParams(action.payload.searchParam)),
        put(setShareholderSearchParams(action.payload.searchParam)),
        put(setChargesSearchParams(action.payload.searchParam)),
        put(setRegionSearchParams(action.payload.searchParam))
    ])
}

export function* watchResetAllSearchParams(){
    yield takeLatest(RESET_ALL_SEARCH_PARAMS,resetAllSearchParamsSaga);
}
const { SR_ONE_TIME_PURCHASE_COMPANY_REPORT } = require("../../common/subscriptionConfigConst");

const products = {
    pomandaPlusMonthly: {
        productId: "pomandaPlusMonthly",
        productTitle: "Pomanda Plus",
        productName: "PomandaPlus",
        price: {
            value: 30,
            period: "month",
            annualSaving: 20
        },
        freeTrialAvailable: true,
        smallDescription: "Enjoy 48 hours free* access to Pomanda Plus before your subscription begins.",  
        getBtnData: (trialPeriod,userSubscription) => {
            let text = trialPeriod ? "Try for Free" : 'Purchase Now', disabled = false, isUpgradeBtnOn = false,isUpgradeWithoutBtn = false, contactTeam = false;
            const isPomandaPlusActive = userSubscription?.productName === "PomandaPlus" && userSubscription?.active === "yes";
            if(isPomandaPlusActive && userSubscription?.interval === "month"){
                text = "Subscribed";
                disabled = true;
                isUpgradeBtnOn = false;
            } else if(isPomandaPlusActive && userSubscription?.interval === "year"){
                text = "Subscribed";
                disabled = true;
            } else {
                isUpgradeWithoutBtn = isPomandaPlusActive && userSubscription?.interval === "day" ? true : false;
                text = isPomandaPlusActive && userSubscription?.interval === "day" ? "Upgrade Now" : text;
            }
            return { text, disabled, isUpgradeBtnOn, isUpgradeWithoutBtn };
        },
        btn: {
            text: "Subscribe Now",
            disabled: null
        },
        periodTextNextToPrice: 'monthly',
        textNextToPrice: () => 'Pomanda Plus',
        note: "*Free trial does not include exports or a free credit report",        
        upgradeProductInfo: {
            productId: "pomandaPlusYearly",
            productName: "PomandaPlus",
            price: {
                value: 19.99,
                period: "year",
            } 
        },
        featureHeading: "All of Day Pass and:",
        featurePoints: ["1,000 Exports Per Month","5 Company Reports","1 Free Credit Report-Monthly","Save Custom Searches", "Competitor Analysis (Up to 5)"],
        planComparisionPoints: {
            companyProfileViews: "∞",
            directorProfileViews: "∞",
            healthcheck: "yes",
            powerSearchForLeadGeneration: "yes",
            exportLeads: "1000",
            estimatedBusinessValuations: "Range",
            competitorAnalysis: "5 companies",
            companyReports: "5",
            monthlyFreeCreditReport: "1",
            saveCustomSearches: "yes",
            apiIntegration: "no",
            whiteLabeling: "no",
            batchUpload: "no",
            powerSearchAlerts: "no"
        },
    },
    pomandaPlusYearly: {
        productId: "pomandaPlusYearly",
        productTitle: "Pomanda Plus",
        productName: "PomandaPlus",
        price: {
            value: 24,
            period: "year",
            annualSaving: 20,
            valueWithoutSaving: 24.99,
            annualValue: 288
        },
        smallDescription: "Enjoy 48 hours free* access to Pomanda Plus before your subscription begins.",
        btn: {
            text: "Try for Free",
            disabled: null
        },
        freeTrialAvailable: true,
        getBtnData: (trialPeriod,userSubscription) => {
            let text = trialPeriod ? "Try for Free" : 'Purchase Now', disabled = false, isUpgradeWithoutBtn = false, contactTeam = false;
            const isPomandaPlusActive = userSubscription?.productName === "PomandaPlus" && userSubscription?.active === "yes";
            if(isPomandaPlusActive && userSubscription?.interval === "year"){
                text = "Subscribed";
                disabled = true;
            } else if(isPomandaPlusActive && userSubscription?.interval === "month"){
                text = userSubscription?.isPomandaPlus5KUser ? "Subscribed" : "Upgrade Now";
                isUpgradeWithoutBtn = userSubscription?.isPomandaPlus5KUser ? false : true;
                disabled = userSubscription?.isPomandaPlus5KUser ? true : false;
            } else {
                isUpgradeWithoutBtn = isPomandaPlusActive && userSubscription?.interval === "day" ? true : false;
                text = isPomandaPlusActive && userSubscription?.interval === "day" ? "Upgrade Now" : text;
                // text = trialPeriod > 0 ? `${trialPeriod} Day Free trial` : text;
            }
            return { text, disabled, isUpgradeWithoutBtn };
        },
        periodTextNextToPrice: 'monthly',
        textNextToPrice: (annualValue) => `(£ ${annualValue} Billed Annually)`,
        note: "*Free trial does not include exports or a free credit report", 
        featureHeading: "All of Day Pass and:",
        featurePoints: ["1,000 Exports Per Month","5 Company Reports","1 Free Credit Report-Monthly","Save Custom Searches", "Competitor Analysis (Up to 5)"],
        planComparisionPoints: {
            companyProfileViews: "∞",
            directorProfileViews: "∞",
            healthcheck: "yes",
            powerSearchForLeadGeneration: "yes",
            exportLeads: "1000",
            estimatedBusinessValuations: "Range",
            competitorAnalysis: "5 companies",
            companyReports: "5",
            monthlyFreeCreditReport: "1",
            saveCustomSearches: "yes",
            apiIntegration: "no",
            whiteLabeling: "no",
            batchUpload: "no",
            powerSearchAlerts: "no"
        }
    },
    enterprise: {
        type: "none",
        productId: "enterprise",
        productTitle: "Enterprise",
        productName: "Enterprise",
        trial: false,
        subHeading: "Contact Us",
        noOfUsers: "5+ Users",
        smallDescription: "Contact us to discuss Pomanda Plus for teams and larger organisations.",
        btn: {
            text: "Contact Us",
            type: "yellowBorder"
        },
        featureHeading: "Customisable features:",
        featurePoints: ["Data Cleansing & Enrichment","5,000+ Monthly Exports", "Bespoke Lead Generation","Valuation Subscriptions", "Business Plan Subscriptions", "API Integration Available", "White Labelling Available", "Account Manager"],
        planComparisionPoints: {
            companyProfileViews: "∞",
            directorProfileViews: "∞",
            healthcheck: "yes",
            powerSearchForLeadGeneration: "yes",
            exportLeads: "5,000+ per month",
            estimatedBusinessValuations: "Valuation Multiples Data",
            competitorAnalysis: "yes",
            companyReports: "∞",
            monthlyFreeCreditReport: "10+",
            saveCustomSearches: "yes",
            apiIntegration: "On Request",
            whiteLabeling: "On Request",
            batchUpload: "Coming Soon",
            powerSearchAlerts: "Coming Soon"
        }
    },
    pomandaPlusFreeReport: {
        productId: "pomandaPlusFreeReport",
        productTitle: "Pomanda Plus",
        productName: "freeCR",
        freeCreditReport: {
            noOfReport: 1,
            period: "month"
        },
        subHeading: "Free Credit Report",
        smallDescription: "Get more out of Pomanda with unlimited company information",
        btn: {
            text: "claim now",
            type: "yellowBackground"
        }
    },
    oneTimePurchaseCreditReport: {
        productId: "oneTimePurchaseCreditReport",
        productTitle: "Immediately available, up to date Credit Report",
        productName: "oneTimePurchase",
        price: {
            value: 9.99,
            fontSize: "big",
            period: "one_time"
        },
        serviceName: "",
        getSmallDescription: (name) => `Purchase one time credit report for <span class='font-weight-bold'>${name}</span>`,
        smallDescription: "",
        btn: {
            text: "purchase",
            type: "yellowBackground"
        }
    },
    percent30OffReport: {
        productId: "percent30OffReport",
        productTitle: "Discounted Credit Report",
        productName: "percent30OffReport",
        price: {
            value: 6.99,
            fontSize: "big",
            period: "one_time"
        },
        discount: {
            percentage: 30,
            position: "top"
        },
        serviceName: "CreditReport",
        getSmallDescription: (name) => `Purchase credit report for <span class='font-weight-bold'>${name}</span>`,
        smallDescription: "",
        btn: {
            text: "purchase",
            type: "yellowBackground"
        }
    },
    pomandaPlusOneDay: {
        isPeriodic: false,
        isNew: false,
        productId: "pomandaPlusOneDay",
        productTitle: "Day Pass",
        productName: "PomandaPlus",
        price: {
            value: 10,
            period: "one_day"
        },
        smallDescription: "Access Pomanda Plus for 24 hours with no commitment.",
        btn: {
            text: "Purchase Now",
            disabled: null
        },
        getBtnData: (trialPeriod, userSubscription) => {
            let text = "Purchase Now", disabled = false;
            const isPomandaPlusActive = userSubscription?.productName === "PomandaPlus" && userSubscription?.active === "yes";
            if(isPomandaPlusActive && userSubscription?.interval === "day"){
                text = "Subscribed";
                disabled = true;
            }
            return {text, disabled};
        },
        featureHeading: "All of Free and:",
        featurePoints:["Unlimited Company or Director Profile Views","Power Search for Lead Generation","100 Exports",'“Estimated” Business Valuation (Range)'],
        planComparisionPoints: {
            companyProfileViews: "∞",
            directorProfileViews: "∞",
            healthcheck: "yes",
            powerSearchForLeadGeneration: "yes",
            exportLeads: "100",
            estimatedBusinessValuations: "Range",
            competitorAnalysis: "yes",
            companyReports: "no",
            monthlyFreeCreditReport: "no",
            saveCustomSearches: "no",
            apiIntegration: "no",
            whiteLabeling: "no",
            batchUpload: "no",
            powerSearchAlerts: "no"
        }
    },
    oneTimePurchaseCompanyReport: {
        productId: "oneTimePurchaseCompanyReport",
        productTitle: "Immediately available, up to date Credit Report",
        productName: "oneTimePurchase",
        price: {
            value: 4.99,
            fontSize: "big",
            period: "one_time"
        },
        serviceName: SR_ONE_TIME_PURCHASE_COMPANY_REPORT,
        smallDescription: "",
        btn: {
            text: "purchase",
            type: "yellowBackground"
        }
    },
    oneCredit: {
        productId: "oneCredit",
        productTitle: "Credit Reports",
        productName: "oneCredit",
        subHeading: "Use 1 Credit",
        smallDescription: "",
        btn: {
            text: "download",
            type: "yellowBackground"
        }
    },
    free: {
        productId: "free",
        productTitle: "Free",
        productName: "PomandaPlus",
        smallDescription: "Pomanda Basic Account",
        price: {
            value: 0,
            period: "monthly"
        },
        btn: {
            text: "Create Account",
            disabled: null
        },
        periodTextNextToPrice: 'monthly',
        featureHeading: "What you can do:",
        featurePoints: ["3 Company or Director Profile Views", "Healthcheck"],
        planComparisionPoints: {
            companyProfileViews:"3 Company Profile Views",
            directorProfileViews:"3 Director Profile Views",
            healthcheck:"yes",
            powerSearchForLeadGeneration:"no",
            exportLeads:"no",
            estimatedBusinessValuations:"no",
            competitorAnalysis:"no",
            companyReports:"no",
            monthlyFreeCreditReport:"no",
            saveCustomSearches:"no",
            apiIntegration:"no",
            whiteLabeling:"no",
            accountManager:"no"  
        }            
    },
    pomandaPlus5k:{
        productId: "pomandaPlusYearly",
        productTitle: "Pomanda Plus 5k",
        productName: "PomandaPlus5K",
        price: {
            value:100,
            period: "year"
        },
         btn: {
            text: "Upgrade Now",
            disabled: null,

        },
        getBtnData: (userSubscription) => {
            let text = "Upgrade Now", disabled = false;
            const isPomandaPlusActive = userSubscription?.productName === "PomandaPlus" && userSubscription?.active === "yes";
            if(isPomandaPlusActive && userSubscription?.isPomandaPlus5KUser){
                text = "Subscribed";
                disabled = true;
            }
            return {text, disabled};
        },
        periodTextNextToPrice: 'per month',
        textNextToPrice: (annualValue) => `(£ ${annualValue} Billed Annually)`,
    },
    pomandaPlus5kMonthly:{
        productId: "pomandaPlusMonthly",
        productTitle: "Pomanda Pro",
        productName: "PomandaPlus5K",
        price: {
            value:120,
            period: "month",
            annualSaving: 20
        },
        freeTrialAvailable: false,
        smallDescription: "Enhance your Pomanda experience by unlocking Pomanda's Pro features.",
        btn: {
            text: "Purchase Now",
            disabled: null,
        },
        getBtnData: (userSubscription) => {
            let text = "Purchase Now", disabled = false, isUpgradeBtnOn = false, isUpgradeWithoutBtn = false;
            const isPomandaPlusActive = userSubscription?.productName === "PomandaPlus" && userSubscription?.active === "yes";
            if(isPomandaPlusActive && userSubscription?.isPomandaPlus5KUser && userSubscription.interval === 'month'){
                text = "Subscribed";
                disabled = true;
            } else if (isPomandaPlusActive && userSubscription?.isPomandaPlus5KUser && userSubscription.interval === "year") {
                text = 'Subscribed';
                disabled = true;
            } else if (isPomandaPlusActive && !userSubscription?.isPomandaPlus5KUser) {
                isUpgradeWithoutBtn = true;
                text = 'Upgrade Now'
            }
            return {text, disabled, isUpgradeBtnOn, isUpgradeWithoutBtn};
        },
        periodTextNextToPrice: 'monthly',
        textNextToPrice: (annualValue) => `(£ ${annualValue} Billed Annually)`,
        note: "No Commitment, Cancel Anytime",
        upgradeProductInfo: {
            productId: "pomandaPlusYearly",
            productName: "PomandaPlus5K",
            productTitle: "Pomanda Plus 5k",
            price: {
                value: 100,
                period: "year",
            } 
        },
        featureHeading: "All Pomanda Plus and:",
        featurePoints: ["Free Business Valuation Report - Monthly","5,000 Monthly Exports","“Estimated” Business Valuation Multiples","Unlimited Company Reports","10 Free Credit Reports -Monthly", "Unlimited Competitor Analysis", "Batch Upload - Coming Soon", "Power Search Alerts - Coming Soon"],
        planComparisionPoints: {
            companyProfileViews: "∞",
            directorProfileViews: "∞",
            healthcheck: "yes",
            powerSearchForLeadGeneration: "yes",
            exportLeads: "5000",
            estimatedBusinessValuations: "Valuation Multiples",
            competitorAnalysis: "∞",
            companyReports: "∞",
            monthlyFreeCreditReport: "10",
            saveCustomSearches: "yes",
            apiIntegration: "no",
            whiteLabeling: "no",
            batchUpload: "Coming Soon",
            powerSearchAlerts: "Coming Soon"
        },
    },
    pomandaPlus5kYearly:{
        productId: "pomandaPlusYearly",
        productTitle: "Pomanda Pro",
        productName: "PomandaPlus5K",
        price: {
            value: 100,
            period: "year",
            annualSaving: 20,
            valueWithoutSaving: 120,
            annualValue: 1200
        },
        smallDescription: "Enhance your Pomanda experience by unlocking Pomanda's Pro features.",
        btn: {
            text: "Purchase Now",
            disabled: null,
        },
        freeTrialAvailable: false,
        getBtnData: (userSubscription) => {
            let text = "Purchase Now", disabled = false, isUpgradeWithoutBtn = false;
            const isPomandaPlusActive = userSubscription?.productName === "PomandaPlus" && userSubscription?.active === "yes";
            if(isPomandaPlusActive && userSubscription?.isPomandaPlus5KUser && userSubscription.interval === 'month'){
                text = "Upgrade Now";
                isUpgradeWithoutBtn = true
            } else if (isPomandaPlusActive && userSubscription?.isPomandaPlus5KUser && userSubscription.interval === "year") {
                text = 'Subscribed';
                disabled = true
            } else {
                isUpgradeWithoutBtn = isPomandaPlusActive && !userSubscription?.isPomandaPlus5KUser && (userSubscription?.interval === "day") ? true : false;
                text = isPomandaPlusActive && !userSubscription?.isPomandaPlus5KUser ? 'Upgrade Now' : "Purchase Now"
            }
            return {text, disabled, isUpgradeWithoutBtn};
        },
        periodTextNextToPrice: 'monthly',
        textNextToPrice: (annualValue) => `(£ ${annualValue} Billed Annually)`,
        note: "No Commitment, Cancel Anytime",
        featureHeading: "All Pomanda Plus and:",
        featurePoints: ["Free Business Valuation Report - Monthly","5,000 Monthly Exports", "“Estimated” Business Valuation Multiples","Unlimited Company Reports","10 Free Credit Reports -Monthly", "Unlimited Competitor Analysis", "Batch Upload - Coming Soon", "Power Search Alerts - Coming Soon"],
        planComparisionPoints: {
            companyProfileViews: "∞",
            directorProfileViews: "∞",
            healthcheck: "yes",
            powerSearchForLeadGeneration: "yes",
            exportLeads: "5000",
            estimatedBusinessValuations: "Valuation Multiples",
            competitorAnalysis: "∞",
            companyReports: "∞",
            monthlyFreeCreditReport: "10",
            saveCustomSearches: "yes",
            apiIntegration: "no",
            whiteLabeling: "no",
            batchUpload: "Coming Soon",
            powerSearchAlerts: "Coming Soon"
        },
    }
}

module.exports = products;

import React from "react";
import "./ValuationReportPPCHeader.css";
import "../../BusinessPlanPPCHomePage/InfiniteScrollComponent/InfiniteScrollComponent.css";
import MediaQuery from "react-responsive";
import FeatureDetailsWithForm from '../../FeatureDetailsWithForm/FeatureDetailsWithForm'
import { getCountryListRequest } from "../../../redux/actions/countryAction";
import { withRouter } from 'react-router';

const ValuationReportPPCHeader = (props) => {
    const {
        heading = "",
        subHeading= "",
        id = '',
        imageUrl = {},
        formObject = [
            {
                label: "Name",
                name: "companyName",
                type: "text",
                placeholder: "Enter Company Name",
                isRequired: true
            },
            {
                label: "Location",
                name: "location",
                type: "select",
                placeholder: "Select Country",
                isRequired: true,
                actionDispatch: getCountryListRequest()
            },
            {
                label: "Revenue / Sales",
                name: "revenueSales",
                type: "text",
                inputMode: "numeric",
                placeholder: "Enter Latest Sales",
                isRequired: true,
                subText:true,
                cssClass:"input-field-reven-sale"
            }
        ],
        eventPage = ""
    } = props;

    return (
        <div id={id} className="valuation-report-ppc-header">
            <div className="valuation-ppc-header-container">
                {heading ? <h1 className="valuation-report-ppc-heading m-0" dangerouslySetInnerHTML={{ __html: heading }} /> : ""}
                {subHeading && <p className="valuation-report-ppc-sub-heading">{subHeading}</p>}
                <FeatureDetailsWithForm formData={formObject} eventPage={eventPage} formHeading="Quick Start" />
            </div>
            {imageUrl.desktopTabImageUrl ? <div className="valuation-ppc-image-container d-flex justify-content-center">
                <MediaQuery minWidth={576}>
                    <picture>
                        <source srcSet={imageUrl.desktopTabImageUrl.webpImageUrl} />
                        <img className="valuation-ppc-image" alt={imageUrl.desktopTabImageUrl.alt} src={imageUrl.desktopTabImageUrl.image} width={imageUrl.desktopTabImageUrl.width} height={imageUrl.desktopTabImageUrl.height} />
                    </picture>
                </MediaQuery>
            </div> : null}
        </div >
    )
};

export default withRouter(ValuationReportPPCHeader);
import { REMOVE_VALUATION_ONBOARD_USER_DATA, SET_INACTIVE_VALUATION_ONBOARD_DATA_FAILED, SET_INACTIVE_VALUATION_ONBOARD_DATA_SUCCESS, SET_VALUATION_ONBOARD_USER_DATA } from "../actions/actionConstants";

const valuationReducer = (state = {
    valutionOnboardObj: {}
}, action) => {
    switch (action.type) {
        case SET_VALUATION_ONBOARD_USER_DATA:
            return {
                ...state,
                valutionOnboardObj: { ...state.valutionOnboardObj, ...action.payload.valuationData }
            }
        case REMOVE_VALUATION_ONBOARD_USER_DATA:
            return {
                ...state,
                valutionOnboardObj: { }
            }
        default:
            return { ...state }
    }
}

export default valuationReducer;

import localStorageApi from './localStorageApi';

export const commonFetch = async (URL, method, body, headers) => {
    try {
        let userTrackerObj = localStorageApi.getUserData()
        const resttoken = localStorageApi.getCookie("resttoken")
        headers.set("resttoken", resttoken)
        if (userTrackerObj) {
            headers.set("trackerid", userTrackerObj.trackerId)
            headers.set("sessionid", userTrackerObj.sessionId)
            if (userTrackerObj.accessToken !== "") {
                headers.set("accesstoken", userTrackerObj.accessToken)
            }
        } else {
            headers.set("trackerid", "")
            headers.set("sessionid", "")
        }

        let sendData = {}
        if (method === "POST") {
            sendData = {
                method: method,
                body: JSON.stringify(body),
                headers: headers
            }
        } else if (method === "PUT") {
            sendData = {
                method: method,
                body: body,
                headers: headers
            }
        } else {
            sendData = {
                method: method,
                headers: headers
            }
        }
        const response = await fetch(URL, sendData);
        if (response.status === 200) {
            const data = response.headers.get("Content-Type") === "text/html" ? await response.text() : await response.json();
            return data;
        } else if (response.status === 403) {

            let userTrackerObj = localStorageApi.getUserData()
            userTrackerObj.accessToken = ""
            localStorageApi.setUserData(userTrackerObj)

            window.location.assign("/")
        } else if (response.status === 401 || response.status === 406) {
            const res = await response.json();
            // if (res.error && res.restTokenError) {
            //     localStorageApi.removeCookie("resttoken")
            //     setTimeout(()=>{
            //         location?.reload(true);
            //     },1000)
            // } else {
            //     return res
            // }
            return res
        } else if (response.status === 409) {
            const res = await response.json();
            return res
        } else {
            const res = await response.json();
            return {...res, status: response.status}
            
        }
    } catch (e) {
        console.log("Inside catch");
        throw e
    }
};

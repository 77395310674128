import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./Homepage.css";
import {
    sendActivityTracking, setChoosePackageModalData,
    setHeaderSingleSearchOptions, setHideHeader
} from '../../redux/actions/commonAction';
import {
    resetAllSearchParams,
} from '../../redux/actions/searchAction';
import { setCompanyProfile } from '../../redux/actions/companyAction';
import SingleSearchBar from '../SingleSearchBar/SingleSearchBar';
import analyticsApi from '../../api/analyticsApi';
import {
    EP_HOMEPAGE, ET_HOMEPAGE_ONLOAD, ET_HOMEPAGE_SINGLE_SEARCH,
    HOMEPAGE_SEARCHBAR_SEARCH, HOMEPAGE_SEARCHBAR_OPTION, HOMEPAGE_SEARCHBAR_ICON,
    ET_FIND_COMPANY_DIRECTOR_LINK_CLICK, ET_CREDIT_REPORT_LINK_CLICK,
    ET_VALUE_BUSINESS_LINK_CLICK,
    ET_LEAD_GENERATION_LINK_CLICK,
    ET_ACCESS_FUNDING_LINK_CLICK,
    ET_SCORECARD_LINK_CLICK,
    ET_ESTIMATE_LINK_CLICK,
    ET_INDUSTRY_INSIGHT_LINK_CLICK,
    HOMEPAGE_SEARCHBAR_ENTER,
    ET_PRODUCT_HOMEPAGE_SEARCHBAR_ENTER_CLICK,
    ET_BUSINESS_VALUATION_BUTTON_CLICK,
    ET_BUILD_BUSINESS_PLAN_BTN_CLICK,
    ET_ABOUT_US_LINK_CLICK
} from '../../common/trackingConstants';
import { checkResetPasswordLinkValid } from '../../redux/actions/loginSignupAction';
import { isBrowser, PROSPER_USER_TYPE, REACT_APP_GOOGLE_INDEX } from '../../common/config';
import { Helmet } from "react-helmet";
import MediaQuery from 'react-responsive';
import HomePageCardComponent from '../HomePageCard/HomePageCardComponent';
import { getSolutionMenuItems } from '../WhatWeDo/Constants';
import HomepageFooterV3 from '../HomePage/HomepageFooterV3/HomepageFooterV3';
import { SR_TRY_POMANDA_PLUS } from '../../common/subscriptionConfigConst';
import DynamicSvg from '../../DynamicSvg';
import YellowBgButton from '../BusinessPlanTemplate/BusinessPlanComponents/YellowBgButton/YellowBgButton';
import { Link } from 'react-router-dom'
import { getCurrencySymbol } from '../../common/CurrencyConverter';

export const AI_PRODUCTS = [
    {
        label: "Business Valuation Calculator & Report",
        price: {
            aud: {
                value: 190,
            },
            chf: {
                value: 115,
            },
            eur: {
                value: 115,
            },
            gbp: {
                value: 100,
            },
            jpy: {
                value: 20000,
            },
            usd: {
                value: 125,
            },
            postText: "per report"
        },
        features: [
            "Editable Financial & Industry Data",
            "30-Day Calculator Access & Instant Report Generation",
            "Six Valuation Methodologies",
            "Current Industry Multiples",
            "Enterprise Package Available"
        ],
        buttonObj: {
            label: "GET VALUATION REPORT",
            url: "/companyvaluation"
        },
        imgUrl: "/assets/svgs/pom_ai_product_val.svg",
        eventType: ET_BUSINESS_VALUATION_BUTTON_CLICK
    },
    {
        label: "AI Business Plans",
        price: {
            aud: {
                value: 230,
            },
            chf: {
                value: 140,
            },
            eur: {
                value: 140,
            },
            gbp: {
                value: 120,
            },
            jpy: {
                value: 24000,
            },
            usd: {
                value: 150,
            },
            postText: "per report"
        },
        features: [
            "Integrated AI Data Analysis",
            "27 Section Business Plan",
            "Smart Template with Tailored Section Integration",
            "60-Day Customisation Period",
            "Enterprise Package Available"
        ],
        buttonObj: {
            label: "BUILD YOUR PLAN",
            url: "/businessplan"
        },
        imgUrl: "/assets/svgs/pom_ai_product_bp.svg",
        eventType: ET_BUILD_BUSINESS_PLAN_BTN_CLICK
    }
]

const AiProductcard = (props) => {
    const {
        label = "",
        price = null,
        features = [],
        buttonObj = null,
        imgUrl = "",
        eventType = ""
    } = props.product;
    return (
        <div className='ai-product-card' key={props?.key}>
            <div className='ai-product-card-header'>
                <div className='ai-product-card-price-wrapper'>
                    <p className='ai-product-card-label'>{label}</p>
                    <div className='ai-product-card-price'>
                        <div>
                            <span className='ai-product-card-currency'>{getCurrencySymbol(props.selectedCurrency)}</span>
                            <span className='ai-product-card-price-val'>{price[props.selectedCurrency].value}</span>
                        </div>
                        <span className='ai-product-card-price-text'>{price.postText}</span>
                    </div>
                </div>
                <MediaQuery maxWidth={991.98}>
                    <div className='ai-product-card-img-wrapper'>
                        <img src={imgUrl} />
                    </div>
                </MediaQuery>
            </div>
            {features.length ? <div className='ai-product-card-features'>
                <div className='ai-product-card-feature-list'>
                    <p className='ai-product-card-feature-heading'>FEATURES</p>
                    {features.map((feature) => {
                        return (<div className='ai-product-card-feature-field'>
                            <img src="/assets/svgs/pom_ai_tick_icon_with_bg.svg" width="24" height="24" className='mr-2' />
                            <p className='ai-product-card-feature-label'>{feature}</p>
                        </div>)
                    })}
                </div>
                <MediaQuery minWidth={992}>
                    <img src={imgUrl} width="334" />
                </MediaQuery>
            </div> : null}
            <div className='ai-product-card-btn-sec'>
                <button className="yellow-E1A132-btn white-color-text w-100 font-weight-bold" onClick={()=>{props.handlePomandaCardClick(buttonObj.url, eventType)}}>{buttonObj.label}</button>
            </div>
        </div>
    )
}

const companySeoTitle = 'Pomanda AI | Business Plans & Valuations | Global Business Solutions'
const metaData = "Pomanda merges best-in-class data with AI, delivering data-driven solutions for businesses worldwide. Get a business valuation or generate a tailored business plan today."

class Homepage extends Component {

    constructor() {
        super();
        this.state = {
            searchtext: ""
        }
    }

    componentDidMount() {
        const {
            match = {},
            companyQueryData = {}
        } = this.props;
        // this.props.dispatchSetHeaderSingleSearchOptions({
        //     tryPowerSearch: true,
        //     eventPage: EP_HOMEPAGE
        // });
        // this.props.dispatchSetCompanyProfile({})
        analyticsApi.onPageLoad(window.location.pathname)
        this.props.dispatchSendActivityTracking({
            eventPage: EP_HOMEPAGE,
            eventType: ET_HOMEPAGE_ONLOAD
        });
        window.scrollTo({ top: 0 });
        // if (match.params && match.params.email && match.params.email !== "") {
        //     this.props.dispatchCheckResetPasswordLinkValidity(match.params.email);
        // }
        // let newCompanyQueryData = JSON.parse(JSON.stringify(companyQueryData))
        // newCompanyQueryData.isolatedSearchText  = ""
        // this.props.dispatchResetAllSearchParams(newCompanyQueryData);
        this.handleIntersectionObservor("lazy-bg")
    }

    componentDidUpdate = (prevProps, _prevState) => {
        const {
            headerSearchText = "",
            headerProps = {}
        } = this.props;
        // if (headerSearchText && headerSearchText !== prevProps.headerSearchText) {
        //     this.setState({ searchtext: "" });
        // }
        headerProps?.backgroundDesktop !== "transparent" && this.props.dispatchSetHideHeader({
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent",
            isSingleSearchbarSticky: false,
        });

    }

    componentWillUnmount = () => {
        analyticsApi.onPageUnLoad();
        // this.props.dispatchSetHeaderSingleSearchOptions({
        //     tryPowerSearch: false,
        // });
        this.props.dispatchSetHideHeader({
            backgroundTab: "blue",
            backgroundMob: "blue",
            backgroundDesktop: "blue",
            isSingleSearchbarSticky: true,
        });
        window.scrollTo({ top: 0 });
    }

    handleIntersectionObservor = (className) => {
        const targetElements = document.querySelectorAll(`${className ? '.' + className : ""}`);
        const observer = new IntersectionObserver((entries, observer) => {
            entries.map((entry) => {
                if (entry.isIntersecting) {
                    const lazyBgElement = entry.target;
                    lazyBgElement.classList.add("lazyloaded-bg");
                    lazyBgElement.classList.remove('lazy-bg');
                    observer.unobserve(lazyBgElement);
                }
            })
        });
        targetElements.length > 0 && targetElements.forEach((target) => {
            observer.observe(target);
        })
    }

    handleTracking = (trackingObj) => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_HOMEPAGE,
            ...trackingObj
        })
    }

    handlePomandaCardClick = (url, eventType) => {
        this.handleTracking({ eventType });
        this.props.history.push(url);
    }

    render() {
        const {
            staticContext = {},
            history = {},
            userInfo = {},
            whiteLabelConfig = {},
            countryBasedCurrency
        } = this.props;
        let href, origin;
        if (isBrowser) {
            href = window.location.href
            origin = window.location.origin
        } else {
            href = `${staticContext.baseHref}${history.location.pathname}`
            origin = staticContext.baseHref
        }
        const iswhiteLabelConfig = whiteLabelConfig?.header ? true : false;
        const selectedCurrency = userInfo?.aiCurrency || countryBasedCurrency ;
        return (
            <div className="homepage">
                <Helmet>
                    <title>{companySeoTitle}</title>
                    <meta property="og:title" content={companySeoTitle} />
                    <meta name="description" content={metaData} />
                    {!REACT_APP_GOOGLE_INDEX && <meta name="robots" content="noindex" />}
                    <link rel="canonical" href={href} />
                </Helmet>
                <div className='homepage-header-section hero-section-with-bg-video'>
                    <video className="hero-section-video" autoPlay loop muted playsInline webkit-playsinline="true">
                        <source src="/assets/videos/pom_ai_homepage_video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="gradient-over-video"></div>
                    <div className="homepage-content-main homepage-content-max">
                        <h1 className="homepage-heading">Pomanda AI</h1>
                        <div className='homepageV3-searchbar-info-wrapper'>
                            <h2 className='homepage-info homepageV3-searchbar-max'>Empowering Businesses Worldwide with Data-Driven Solutions</h2>
                        </div>
                        <div className='ai-products-section'>
                            {AI_PRODUCTS?.map((productObj, i) => {
                                return <AiProductcard product={productObj}
                                    key={`product_card_${i}`}
                                    handlePomandaCardClick={this.handlePomandaCardClick}
                                    selectedCurrency={selectedCurrency}
                                />
                            })}
                        </div>
                    </div>
                </div>
                <div className='homepage-abt-section homepage-content-max'>
                    <div className='homepage-abt-section-content'>
                        <p className='homepage-abt-section-heading'>About Pomanda</p>
                        <div>
                            <p className='homepage-abt-section-info'>At Pomanda, our mission is to empower professionals and business owners worldwide by providing streamlined, data-driven business solutions.</p>
                            <Link to={"/about"} onClick={() => this.handleTracking({eventType: ET_ABOUT_US_LINK_CLICK})} className="homepage-abt-section-link">About Us</Link>
                        </div>
                    </div>
                    <img className="homepage-abt-section-img" src="/assets/images/pom_ai_homepage_about.png" width="1216" height="480" />
                </div>

                <div className="homepage-footer-section">
                    <HomepageFooterV3 />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    headerSearchText: state.common.headerSearchText,
    headerSingleSearchOptions: state.common.headerSingleSearchOptions,
    headerProps: state.common.headerProps,
    userInfo: state.userProfile.userInfo,
    countryBasedCurrency: state.userProfile.countryBasedCurrency,
    whiteLabelConfig: state.common.whiteLabelConfig,
    headerProps: state.common.headerProps
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSetHideHeader: (headerProps) => dispatch(setHideHeader(headerProps)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj))
    // dispatchResetAllSearchParams: searchParam => dispatch(resetAllSearchParams(searchParam)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Homepage)
const apiFilterParser = (filter) => {
    const newFilter = {};
    const filterKeys = Object.keys(filter);
    filterKeys.forEach((key) => {
        const filterType = filter[key].type;
        switch (filterType) {
            case "location":
            case "industry":
                newFilter[key] = getTreeFilter(filter[key]);
                break;
            case "check":
            case "score":
            case "tags":    
            case "radio":
            case "text":
            case "range":
            case "financialrange":
            case "yearrange":
            case "daterange":
                newFilter[key] = getOtherFilter(filter[key]);
                break; 
            default:
                return false
        }
    })
    return newFilter 
}

export default apiFilterParser;

const getOtherFilter = (filter) => {
    const fieldKeys = Object.keys(filter.fields);
    let renderedFilter = {
        name: filter.name,
        type: filter.type,
        valueType: filter.valueType ? filter.valueType : null,
        fields: {}
    }
    fieldKeys.forEach((key) => {
        const {
            value, 
            type, 
            fieldNames
        } = filter.fields[key];
        renderedFilter.fields[key] = {
            value, 
            type, 
            fieldNames
        }
    })
    return renderedFilter;
}

const getTreeFilter = (filter) => {
    const fieldKey = Object.keys(filter.fields)[0];
    let renderedFilter = {
        name: filter.name,
        type: filter.type,
        fields: {}
    }
    const {
        labels = [], 
        type, 
    } =  filter.fields[fieldKey];
    let value = [];
    labels.forEach((label) => {
        value.push(label.nodeValue);
    })
    renderedFilter.fields[fieldKey] = {
        value, 
        type, 
    };
    return renderedFilter;
}
import React, { Component } from 'react';
import CookieConsent from "react-cookie-consent";
import './CookieComponent.css';
import { COOKIE_NOTICE } from "../../common/config";
import analyticsApi from '../../api/analyticsApi';
class CookieComponent extends Component {
    render() {
        return (
            <CookieConsent
                location="none"
                containerClasses="custom-cookie-container"
                buttonText="I understand"
                buttonId="pomandae2e-cookie-consent"
                cookieName="pomanda-cookie-consent"
                style={{ background: "rgba(73,73,73,0.85)", alignItems: 'center', zIndex: '1000', display: "block", left: "50%", bottom: "50%", width: "21%", border: "2px solid white" }}
                buttonStyle={{ fontSize: "13px", fontWeight: '600', color: '#FFFFFF', height: '31px', width: '113px', border: '1px solid var(--pomanda-selective-color)', borderRadius: '2px', backgroundColor: 'var(--pomanda-selective-color)', marginLeft: "50%", marginTop: "0", transform: "translateX(-50%)" }}
                onAccept={() => analyticsApi.grantAllConsent()}
            >
                <div className='cookie-outer-div'>
                    <picture>
                        <source type="image/webp" srcSet="/assets/images/logo_pom.webp" className='cookie-img-style' />
                        <img className='cookie-img-style' width="40" height="40" alt="pomanda logo" src="/assets/svgs/pom_ai_logo_mob.svg" />
                    </picture>
                    <p className="cookie-header-privacy mb-0">We Value Your Privacy</p>
                    <p className='cookie-terms-of-use'>{COOKIE_NOTICE}
                        <a href="/assets/Download/Terms_Of_Use.pdf" target="_blank" rel="noopener noreferrer"><u> Terms&nbsp;of&nbsp;Use</u></a> and
                        <a href="/assets/Download/Pomanda_Privacy_Notice.pdf" target="_blank" rel="noopener noreferrer"><u> Privacy&nbsp;Policy </u></a>
                    </p>
                </div>
            </CookieConsent>
        );
    }
}

export default CookieComponent;
import { call, put, takeLatest, all, select, delay } from "redux-saga/effects";
import { reset } from 'redux-form'
import {
    GET_COMPANY_PROFILE_REQUEST, GET_COMPANY_HOUSE_FILINGS_REQUEST,
    GET_COMPANY_HOUSE_DOCUMENT_REQUEST, GET_DIRECTOR_STATUS_LIST,
    GET_DIRECTOR_SHOW_MORE, GET_SHAREHOLDER_SHOW_MORE,
    GET_SHAREHOLDER_TYPE_LIST, GET_INDUSTRY_MULTIPLE_DATA,
    CALL_CREDIT_REPORT_API, CHECK_PDF_CREATION_STATUS,
    SUBMIT_INVESTMENT_INTEREST_REQUEST, SAVE_INDUSTRY_MIX_CHANGES,
    RESET_INDUSTRY_MIX_CHANGES,
    DOWNLOAD_FINANCIALS,
    GET_COMPANY_CHARGES_REQUEST,
    GET_COMPANY_VALUATION_REQUEST,
    SET_COMPANY_VALUATION_USER_EDIT,
    TOGGLE_BUY_CREDIT_REPORT_MODAL,
    GET_COMPANY_VALUATION_REPORT_REQUEST,
    UPDATE_VALUATION_REPORT_CREDIT_SUCCESS,
    GET_INACTIVE_VALUATION_ONBOARD_DATA_REQUEST,
} from "../redux/actions/actionConstants";
import {
    getCompanyProfileFailed, getCompanyProfileSuccess,
    getCompanyHouseFilingsFailed, getCompanyHouseFilingsSuccess,
    getCompanyHouseDocumentFailed, getDirectorStatusListFailed,
    getDirectorStatusListSuccess, getDirectorShowMoreSuccess,
    getDirectorShowMoreFailed, getShareholderTypeListFailed,
    getShareholderTypeListSuccess, getShareholderShowMoreSuccess,
    getShareholderShowMoreFailed, getIndustryMultipleDataFailed,
    getIndustryMultipleDataSuccess, toggleCRDownloadLoadingModal,
    submitInvestmentInterestFailed, toggleSubmitInvestmentInterestModal,
    saveIndustryMixChangesFailed, setIndustryMixLoader,
    setIndustryMixUpdateModal, setIndustryMixUpdateSuccess,
    resetIndustryMixChangesFailed,
    getCompanyChargesSuccess,
    getCompanyValutionDataSuccess,
    getCompanyValutionDataFailed,
    getCompanyValutionUpdatedSuccess,
    resetIndustryMixChangesSuccess,
    setBuyCrModalData,
    updateValuationReportCreditSuccess,
    getCompanyValutionUpdatedFailed,
    resetIndustryMixUpdateSuccess,
    setDailyFreeExpireModalStatus,
    toggleValuationCalculatorModal
} from "../redux/actions/companyAction";
import {
    getCompanyProfileApiCall, getCompanyHouseFilingsApiCall,
    getCompanyHouseDocumentApiCall, getDirectorStatusList,
    getDirectorShowMoreAPI, getShareholderTypeListApi,
    getShareholderShowMoreAPI, getIndustryMultipleApiCall,
    callCreditReportApi, checkPdfCreationApiCall,
    submitInterestApiCall, saveIndustryMixChangesAPI,
    resetIndustryMixChangesAPI,
    downloadFinancialsExcel,
    getSimilarCompaniesApiCall,
    getCompanyChargesApi,
    getCompanyValuationApi,
    setCompanyValuationDataApi
} from '../api/companyApi';
import { checkFreeSearchAnonymousForProduct } from "../redux/actions/subscriptionAction";
import localStorageApi from '../api/localStorageApi';
import { setDailySearchInfo, getUserProfileSuccess } from "../redux/actions/userAccountAction";
import {
    SR_DAILY_SEARCH_LIMIT, DOWNLOAD_REPORT,
    DOWNLOAD_REPORT_READY_MSG, DOWNLOAD_REPORT_ERROR_MSG,
    DOWNLOAD_REPORT_ERROR,
    SR_CREDIT_REPORT_PKG_PURCHASE,
    SR_CREDIT_REPORT
} from '../common/subscriptionConfigConst'
import { toggleSubcriptionScreen } from '../redux/actions/subscriptionAction'
import {
    toggleCommonMsgModal, sendActivityTracking,
    setConfirmationModal,
    // setChoosePackageModalData
} from "../redux/actions/commonAction";
import successModalConstants from '../common/messageModalConstants';
import { PROSPER_USER_TYPE, isBrowser } from '../common/config'
import { ET_BUY_CREDIT_REPORT_MODAL_CLOSE, ET_BUY_CREDIT_REPORT_MODAL_OPEN } from "../common/trackingConstants";
import { setValuationOnboardDataIntoRedux } from "../redux/actions/valuationAction";
// import { COMPANY_PROFILE, ET_CHOOSE_PACKAGE_MODAL_CLOSE, ET_CHOOSE_PACKAGE_MODAL_OPEN } from "../common/trackingConstants";
const downloadModalDataObjFrmRedux = (state) => state.subscription.downloadModalDataObj

function* getCompanyProfileSaga(action) {
    try {
        let { companyId, companyName } = action.payload;
        const response = yield call(getCompanyProfileApiCall, companyId, companyName);
        if (response?.status === 404) throw response;
        if (response.error) {
            if (response.error === 'API_USAGE_LIMIT_EXHAUSTED') {
                yield put(setDailyFreeExpireModalStatus(true));
                yield put(setDailySearchInfo(true))
                // don't remove choose package     
                // yield toggleChoosePackageModal(true, companyId);
            }
            yield put(getCompanyProfileFailed(response.message));
        } else {
            let token = isBrowser && localStorageApi.getUserAuthToken();
            if (!token || token === "" || token === null) {
                yield all([
                    put(getCompanyProfileSuccess(response.companyProfile)),
                    put(checkFreeSearchAnonymousForProduct("company", companyId))
                ]);
            } else {
                if (response.isDailyFreeSearchExpired) {
                    yield put(setDailyFreeExpireModalStatus(true))
                    // don't remove choose package     
                    //     // yield toggleChoosePackageModal(true, companyId);
                    // } else {
                    // yield toggleChoosePackageModal(false, companyId);
                }
                yield all([
                    put(setDailySearchInfo(response.isDailyFreeSearchExpired)),
                    put(getCompanyProfileSuccess(response.companyProfile)),
                    put(getUserProfileSuccess(response?.userInfo?.userProfile, response?.userInfo?.isPomandaUser,
                        response?.userInfo?.userOrderHistory,
                        response?.userInfo?.userCardDetails,
                        response?.userInfo?.valuationReports))
                ]);
                yield call(localStorageApi.updateFreeSearchCountObj, "company", companyId);

            }
        }
    } catch (error) {
        console.log(error, 'error in calling company profile');
        if (error.status === 404) {
            yield put(getCompanyProfileFailed(error.message));
        }
    }
}

function* getCompanyHouseFilingsSaga(action) {
    try {
        const response = yield call(getCompanyHouseFilingsApiCall, action.payload.companyObj);
        if (response.error) {
            yield put(getCompanyHouseFilingsFailed(response.message));
        } else {
            yield put(getCompanyHouseFilingsSuccess(response.data))
        }
    } catch (error) {
        console.log(error);
        yield put(getCompanyHouseFilingsFailed(error.message));
    }
}

function* getCompanyHouseDocumentSaga(action) {
    try {
        const response = yield call(getCompanyHouseDocumentApiCall, action.payload.docId);
        if (response.error) {
            yield put(getCompanyHouseDocumentFailed(response.message));
        } else {
            window.open(response.url, "_self");
        }
    } catch (error) {
        console.log(error);
        yield put(getCompanyHouseFilingsFailed(error.message));
    }
}

export function* watchGetCompanyProfileRequest() {
    yield takeLatest(GET_COMPANY_PROFILE_REQUEST, getCompanyProfileSaga);
}


export function* watchGetCompanyHouseFilingsRequest() {
    yield takeLatest(GET_COMPANY_HOUSE_FILINGS_REQUEST, getCompanyHouseFilingsSaga);
}

export function* watchGetCompanyHouseDocumentRequest() {
    yield takeLatest(GET_COMPANY_HOUSE_DOCUMENT_REQUEST, getCompanyHouseDocumentSaga);
}

function* getDirectorStatusListSaga(action) {
    try {
        const response = yield call(getDirectorStatusList, action.payload.companyId);
        if (response.error) {
            yield put(getDirectorStatusListFailed(response.message));
        } else {
            yield put(getDirectorStatusListSuccess(response.directorStatusList))
        }
    } catch (error) {
        console.log(error);
        yield put(getDirectorStatusListFailed(error.message));
    }
}
export function* watchGetDirectorStatusListRequest() {
    yield takeLatest(GET_DIRECTOR_STATUS_LIST, getDirectorStatusListSaga);
}

function* getDirectorShowMoreSaga(action) {
    try {
        const response = yield call(getDirectorShowMoreAPI, action.payload);
        if (response.error) {
            yield put(getDirectorShowMoreFailed(response.message));
        } else {
            yield put(getDirectorShowMoreSuccess(response))
        }
    } catch (error) {
        console.log(error);
        yield put(getDirectorShowMoreFailed(error.message));
    }
}
export function* watchGetDirectorShowMoreSaga() {
    yield takeLatest(GET_DIRECTOR_SHOW_MORE, getDirectorShowMoreSaga);
}


function* getShareholderTypeListSaga(action) {
    try {
        const response = yield call(getShareholderTypeListApi, action.payload.companyId);
        if (response.error) {
            yield put(getShareholderTypeListFailed(response.message));
        } else {
            yield put(getShareholderTypeListSuccess(response.shTypeList))
        }
    } catch (error) {
        console.log(error);
        yield put(getShareholderTypeListFailed(error.message));
    }
}
export function* watchGetShareholderTypeListSaga() {
    yield takeLatest(GET_SHAREHOLDER_TYPE_LIST, getShareholderTypeListSaga);
}

function* getShareholderShowMoreSaga(action) {
    try {
        const response = yield call(getShareholderShowMoreAPI, action.payload);
        if (response.error) {
            yield put(getShareholderShowMoreFailed(response.message));
        } else {
            yield put(getShareholderShowMoreSuccess(response))
        }
    } catch (error) {
        console.log(error);
        yield put(getShareholderShowMoreFailed(error.message));
    }
}
export function* watchGetShareholderShowMoreSaga() {
    yield takeLatest(GET_SHAREHOLDER_SHOW_MORE, getShareholderShowMoreSaga);
}

function* getIndustryMultipleSaga(action) {
    try {
        const response = yield call(getIndustryMultipleApiCall, action.payload);
        if (response.error) {
            yield put(getIndustryMultipleDataFailed(response.message));
        } else {
            yield put(getIndustryMultipleDataSuccess(response.industryMultiple));
        }
    } catch (error) {
        console.log(error);
        yield put(getIndustryMultipleDataFailed(error.message));
    }
}

export function* watchGetIndustryMultipleSaga() {
    yield takeLatest(GET_INDUSTRY_MULTIPLE_DATA, getIndustryMultipleSaga);
}
export function* callCreditReportApiSaga(action) {
    let resp = yield call(callCreditReportApi, action.payload.crObj)
    if (!resp.error) {
        yield all([
            put(getUserProfileSuccess(resp.userObj, resp.isPomandaUser, resp.userOrderHistory)),
            put(toggleSubcriptionScreen({
                subscriptionToggleStatus: false,
                serviceRequested: "",
            })),
            put(toggleCRDownloadLoadingModal({
                loaderImg: false,
                heading: DOWNLOAD_REPORT,
                subHeading: DOWNLOAD_REPORT_READY_MSG,
                toggleStatus: true,
                showContinueBtn: false,
                userCrDownloadMappingId: resp.userCrDownloadMappingId
            }))
        ]);
        // alert("Please check your email")
    } else {
        yield put(toggleCRDownloadLoadingModal({
            loaderImg: false,
            heading: DOWNLOAD_REPORT_ERROR,
            subHeading: DOWNLOAD_REPORT_ERROR_MSG,
            toggleStatus: true,
            showContinueBtn: false,
        }));
    }
}
export function* watchCallCreditReportSaga() {
    yield takeLatest(CALL_CREDIT_REPORT_API, callCreditReportApiSaga)
}

function* checkPdfCreationStatusSaga(action) {
    let i = 0;
    while (i <= 5) {
        let pdfCreationResp = yield call(checkPdfCreationApiCall, action.payload.cohId);
        if (pdfCreationResp.isPdfError) {
            yield put(toggleCRDownloadLoadingModal({
                loaderImg: false,
                heading: DOWNLOAD_REPORT_ERROR,
                subHeading: DOWNLOAD_REPORT_ERROR_MSG,
                toggleStatus: true,
                showContinueBtn: false
            }));
            break;
        }
        if (!pdfCreationResp.error && pdfCreationResp.isCrPdfGenerated) {
            let downloadModalDataObj = yield select(downloadModalDataObjFrmRedux);
            if (downloadModalDataObj.toggleStatus) {
                yield put(toggleCRDownloadLoadingModal({
                    loaderImg: false,
                    heading: "",
                    subHeading: "",
                    toggleStatus: false,
                    showContinueBtn: false
                }));
            }
            yield delay(1000);
            yield put(toggleCRDownloadLoadingModal({
                loaderImg: false,
                heading: DOWNLOAD_REPORT,
                subHeading: DOWNLOAD_REPORT_READY_MSG,
                toggleStatus: true,
                showContinueBtn: false,
                userCrDownloadMappingId: pdfCreationResp.userCrDownloadMappingId
            }));
            break;
        } else {
            yield delay(5000);
            i++;
        }
    }
}

export function* watchCheckPdfCreationStatus() {
    yield takeLatest(CHECK_PDF_CREATION_STATUS, checkPdfCreationStatusSaga);
}

function* submitInvestmentInterestRequestSaga(action) {
    try {
        yield put(sendActivityTracking(action.payload.trackingInfo));
        const apiResponse = yield call(submitInterestApiCall, action.payload.userContactDetails);
        if (!apiResponse.error) {
            yield all([
                put(toggleSubmitInvestmentInterestModal({ toggleStatus: false })),
                put(toggleCommonMsgModal({
                    toggleStatus: true,
                    heading: successModalConstants.CP_SI_SUCCESS.header,
                    subHeading: successModalConstants.CP_SI_SUCCESS.subHeader,
                    btnText: successModalConstants.CP_SI_SUCCESS.btnText
                })),
                put(reset('submitInterestForm'))
            ])
        } else {
            yield put(submitInvestmentInterestFailed("Details could not be submitted. Please try again later."));
        }
    } catch (error) {
        console.error('inside submitInvestmentInterestRequestSaga', error);
        yield put(submitInvestmentInterestFailed("Details could not be submitted. Please try again later."))
    }
}

export function* watchSubmitInvestmentInterestRequest() {
    yield takeLatest(SUBMIT_INVESTMENT_INTEREST_REQUEST, submitInvestmentInterestRequestSaga);
}

function* saveIndustryMixChangesSaga(action) {
    try {
        const response = yield call(saveIndustryMixChangesAPI, action.payload.industryMixObj);

        if (!response.error) {
            const { companyId, name, industryMixArr, turnover, userId, type } = action.payload.industryMixObj;
            const companyProfileActionObj = {
                payload: {
                    companyId: companyId,
                    companyName: name,
                }
            }
            yield put(getCompanyValutionDataSuccess(response.valuationCalData))
            yield all([
                put(setIndustryMixLoader({ show: false, message: "" })),
                put(setIndustryMixUpdateModal(false)),
                put(setIndustryMixUpdateSuccess(true)),
            ]);
        } else {
            yield all([
                put(saveIndustryMixChangesFailed(response.message)),
                put(setIndustryMixLoader({ show: false, message: "" })),
                put(setIndustryMixUpdateModal(true)),
            ]);
        }
    } catch (error) {
        console.log(error);
        yield put(saveIndustryMixChangesFailed(error));
    }
}

export function* watchSaveIndustryMixChangesSaga() {
    yield takeLatest(SAVE_INDUSTRY_MIX_CHANGES, saveIndustryMixChangesSaga);
}

function* resetIndustryMixChangesSaga(action) {
    try {
        const response = yield call(resetIndustryMixChangesAPI, action.payload);
        if (!response.error) {

            yield put(getCompanyValutionDataSuccess(response.updatedValuation))
            yield put(setConfirmationModal(false));
            yield put(resetIndustryMixUpdateSuccess(true))
            yield put(setIndustryMixLoader({ show: false, message: "" }))
            yield put(setIndustryMixUpdateModal(false));
        } else {
            yield all([
                put(resetIndustryMixChangesFailed(response.message)),
                put(setIndustryMixUpdateModal(false)),
                put(setConfirmationModal(false)),
                put(setIndustryMixLoader({ show: false, message: "" }))
            ]);
        }
    } catch (error) {
        console.log(error);
        yield put(resetIndustryMixChangesFailed(error));
        yield put(setIndustryMixLoader({ show: false, message: "" }));
    }
}

export function* watchResetIndustryMixChangesSaga() {
    yield takeLatest(RESET_INDUSTRY_MIX_CHANGES, resetIndustryMixChangesSaga);
}

function* downloadFinancialsSaga(action) {
    try {
        yield call(downloadFinancialsExcel, action.payload.financials,
            action.payload.companyId, action.payload.companyName);
    } catch (error) {
        console.error('inside downloadFinancialsSaga', error);
    }
}
export function* watchDownloadFinancialsSaga() {
    yield takeLatest(DOWNLOAD_FINANCIALS, downloadFinancialsSaga);
}

// don't remove choose package     
// function* toggleChoosePackageModal(toggleStatus, companyId) {
//     yield all([
//         put(setChoosePackageModalData({
//             toggleStatus,
//             showHeading: true,
//             serviceReq: SR_DAILY_SEARCH_LIMIT,
//             eventPage: COMPANY_PROFILE,
//             showCloseBtn: false
//         })),
//         put(sendActivityTracking({
//             eventPage: COMPANY_PROFILE,
//             eventType: toggleStatus ? ET_CHOOSE_PACKAGE_MODAL_OPEN : ET_CHOOSE_PACKAGE_MODAL_CLOSE,
//             attribute1: companyId,
//             attribute2: SR_DAILY_SEARCH_LIMIT
//         }))
//     ])
// }

function* getCompanyChargesSaga(action) {
    try {
        const response = yield call(getCompanyChargesApi, action.payload.chargesParam);
        if (response.error) {
            // yield put(getDirectorStatusListFailed(response.message));
        } else {
            yield put(getCompanyChargesSuccess(response.companyCharges))
        }
    } catch (error) {
        console.log(error);
        yield put(getDirectorStatusListFailed(error.message));
    }
}

export function* watchGetCompanyChargesRequest() {
    yield takeLatest(GET_COMPANY_CHARGES_REQUEST, getCompanyChargesSaga);
}


function* getCompanyValuationSaga(action) {
    try {
        const response = yield call(getCompanyValuationApi, action.payload.valuationParam);
        const href = isBrowser && window.location.href
        let redirectPath = href.replace("/valuation", "")
        if (!response.error) {
            if (response.companyValuationData) {
                if(response.companyValuationData.inActiveValuationObj){
                    yield put(setValuationOnboardDataIntoRedux(response.companyValuationData.inActiveValuationObj))
                } else {
                    yield put(getCompanyValutionDataSuccess(response.companyValuationData))
                    yield put(setIndustryMixLoader({ show: false, message: "" }))
                    yield put(toggleValuationCalculatorModal({ infoModalSatus: true }));    
                }
            } else {
                window.open(redirectPath, "_self")
                yield put(setIndustryMixLoader({ show: false, message: "" }))
            }
        }
    } catch (error) {
        window.open(redirectPath, "_self")
        yield put(setIndustryMixLoader({ show: false, message: "" }))
        yield put(getCompanyValutionDataFailed(error.message));
    }
}

export function* watchGetCompanyValuationRequest() {
    yield takeLatest(GET_COMPANY_VALUATION_REQUEST, getCompanyValuationSaga);
}

function* setUserEditedCompanyValuationSaga(action) {
    try {
        const response = yield call(setCompanyValuationDataApi, action.payload.userValuationParams);
        yield put(setIndustryMixLoader({ show: false, message: "" }))
        if (response.error) {
        } else {
            yield put(getCompanyValutionUpdatedSuccess(response.result))
        }
    } catch (error) {
        yield put(getCompanyValutionUpdatedFailed(error.message));
    }
}

export function* watchGetCompanyValuationUserRequest() {
    yield takeLatest(SET_COMPANY_VALUATION_USER_EDIT, setUserEditedCompanyValuationSaga);
}

function* toggleBuyCRModalSaga(action) {
    yield put(sendActivityTracking({
        eventPage: action.payload.eventPage,
        eventType: action.payload.toggleStatus ? ET_BUY_CREDIT_REPORT_MODAL_OPEN : ET_BUY_CREDIT_REPORT_MODAL_CLOSE,
        attribute1: action.payload.companyId
    }))
    if (action.payload.toggleStatus) {
        let token = isBrowser && (yield call(localStorageApi.getUserAuthToken));
        if (token && token !== "") {
            const userInfo = state => state.userProfile.userInfo;
            const userObj = yield select(userInfo);
            if (userObj.subscription && userObj.subscription.productName === "PomandaPlus"
                && userObj.subscription.active === "yes") {
                if (userObj.userType !== PROSPER_USER_TYPE && userObj.subscription.freeCR > 0) {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal("freeCR")
                    }
                } else if (userObj.reportPkgSubscription && userObj.reportPkgSubscription.creditCount > 0) {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal("oneCredit")
                    }
                } else if (userObj.userType === PROSPER_USER_TYPE) {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal(PROSPER_USER_TYPE)
                    }
                } else {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal("percent30OffReport")
                    }
                }
            } else if (userObj.reportPkgSubscription && userObj.reportPkgSubscription.creditCount > 0) {
                action.payload = {
                    ...action.payload,
                    ...yield* getObjForBuyCrModal("oneCredit")
                }
            } else if (userObj.userType === PROSPER_USER_TYPE) {
                action.payload = {
                    ...action.payload,
                    ...yield* getObjForBuyCrModal(PROSPER_USER_TYPE)
                }
            } else {
                action.payload = {
                    ...action.payload,
                    ...yield* getObjForBuyCrModal("oneTimePurchase")
                }
            }
        } else {
            action.payload = {
                ...action.payload,
                ...yield* getObjForBuyCrModal("oneTimePurchase")
            }
        }
        yield put(setBuyCrModalData(action.payload));
    }

}

export function* watchToggleBuyCRModalSaga() {
    yield takeLatest(TOGGLE_BUY_CREDIT_REPORT_MODAL, toggleBuyCRModalSaga);
}

export function* getObjForBuyCrModal(productName) {
    switch (productName) {
        case "oneTimePurchase":
            return {
                btnText: "purchase single report £9.99",
                productName: "oneTimePurchase",
                productId: "oneTimePurchaseCreditReport",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true,
                productPeriod: "one_time"
            }
        case "oneCredit":
            return {
                btnText: "purchase report (1 credit)",
                productName: "oneCredit",
                productId: "oneCredit",
                serviceRequested: SR_CREDIT_REPORT_PKG_PURCHASE,
                toggleStatus: true
            }
        case "freeCR":
            return {
                btnText: "purchase report (free)",
                productName: "freeCR",
                productId: "pomandaPlusFreeReport",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true
            }
        case "percent30OffReport":
            return {
                btnText: "purchase single report £6.99",
                productName: "percent30OffReport",
                productId: "percent30OffReport",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true,
                productPeriod: "one_time"
            }
        case PROSPER_USER_TYPE:
            return {
                btnText: "Get Credits",
                productName: "prosperGetCredits",
                productId: "prosperGetCredits"
            }
        default:
            return {
                btnText: "purchase single report £9.99",
                productName: "oneTimePurchase",
                productId: "oneTimePurchaseCreditReport",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true,
                productPeriod: "one_time"
            }
    }
}
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormFeedback, Input } from 'reactstrap';

const SelectInput = props => {
    const dispatch = useDispatch();
    const countryList = useSelector(state => state.country.countryList);
    const { input: {onChange, name, value, type="select", inputStyle={} },
        meta: { error="" }, placeholder, cssClass, disabled, pomandae2e, Tag = Input , actionDispatch} = props;
        
    useEffect(() => {
        dispatch(actionDispatch);
    },[])

    return (
        <React.Fragment>
            <Tag
                pomandae2e={pomandae2e}
                name={name}
                type={type}
                onChange={onChange}
                invalid={error ? true : false}
                placeholder={placeholder}
                className={cssClass}
                value={value || ''}
                autoComplete="off"
                style={inputStyle}
                disabled={disabled}
                required
            >
                <option value="" hidden className='select-input-placeholder-gray-text font-weight-light'>{placeholder}</option>
                {
                    countryList ? countryList.map((option, index) => {
                        return (
                            <option key={`select-input ${index}`}>{option.country}</option>
                        )
                    }) : null
                }
            </Tag>
            <FormFeedback>{error}</FormFeedback>
        </React.Fragment>
    )
}

export default SelectInput;